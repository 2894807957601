// eslint disable-next-line
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../typings/worker-loader/index.d.ts" />

// eslint disable-next-line
// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../../typings/asset-resources/index.d.ts" />

import * as Sentry from '@sentry/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { ReducersMapObject } from 'redux';

import { Route } from 'react-router';
import {
  App as AppInterface,
  Config,
  EventListener,
  EventListenerMapObject,
  Module,
  PagesMapObject,
  Store,
} from '../../interfaces/AppInterface';
import { setConfig } from '../../redux/common/app/actions';
import { createStore, history } from '../../redux/store';
import AppConfig from './config';
import CoreModule from './Core';
import { CategoryChildInterface } from './layout/navigator';
import Root from './Root';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: `${process.env.REACT_APP_VERSION_RELEASE_NAME}@${process.env.REACT_APP_VERSION_HASH}`,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT || process.env.NODE_ENV,
  tracesSampleRate: 1.0,
  attachStacktrace: true,
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: (integrations) =>
    integrations
      .filter((integration) => integration.name !== 'Dedupe')
      .concat([
        Sentry.browserTracingIntegration(),
        Sentry.captureConsoleIntegration(),
        Sentry.replayIntegration({
          maskAllText: false,
          maskAllInputs: false,
        }),
      ]),
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === 'ui.click' && hint) {
      const { target } = hint.event;
      breadcrumb.data = breadcrumb.data || {};
      if (target.ariaLabel) {
        breadcrumb.data.ariaLabel = target.ariaLabel;
      } else if (target.title) {
        breadcrumb.data.title = target.title;
      } else {
        breadcrumb.data.textContent = target.textContent;
      }
    }
    return breadcrumb;
  },
});

export class App implements AppInterface {
  private modules: Module[] = [];

  private reducers: ReducersMapObject = {};

  private pages: PagesMapObject = {};

  private store!: Store;

  private eventListeners: EventListenerMapObject = {};

  constructor() {
    this.addModule(new CoreModule());
  }

  public addModule(module: Module) {
    this.modules.push(module);
  }

  public setConfig(config: Config) {
    AppConfig.setConfig(config);
  }

  public getConfig(): Config {
    return AppConfig.getConfig();
  }

  public setComponent(name: string, component: any) {
    return AppConfig.setComponent(name, component);
  }

  public getComponent(name: string) {
    return AppConfig.getComponent(name);
  }

  public addReducers(reducers: ReducersMapObject) {
    this.reducers = {
      ...this.reducers,
      ...reducers,
    };
  }

  public addRoutes(routes: Route[]) {
    for (const route of routes) {
      AppConfig.addRoute(route);
    }
  }

  public addNavigations(navigations: CategoryChildInterface[]) {
    for (const navigation of navigations) {
      AppConfig.addNavigation(navigation);
    }
  }

  public addServiceWorkers(serviceWorkers: string[]) {
    for (const serviceWorker of serviceWorkers) {
      AppConfig.addServiceWorker(serviceWorker);
    }
  }

  public getModulePages(): PagesMapObject {
    return this.pages;
  }

  public getStore(): Store<any> {
    return this.store;
  }

  public registerEventListener(name: string, listener: EventListener) {
    if (!this.eventListeners[name]) {
      this.eventListeners[name] = [];
    }

    this.eventListeners[name].push(listener);
  }

  public async dispatchEvent(name: string) {
    if (this.eventListeners[name]) {
      return await Promise.all(
        this.eventListeners[name].map(async (listener) => {
          return await listener()();
        })
      );
    }
  }

  public run(domNode: HTMLElement): void {
    this.initModules();

    this.store = createStore(this.reducers);

    this.store.dispatch(setConfig(this.getConfig()));

    (global as any).store = this.store.getState();

    ReactDOM.render(
      <React.Fragment>
        <Root store={this.store} history={history} />
      </React.Fragment>,
      domNode
    );
  }

  private initModules(): void {
    this.modules.forEach((module) => module.init(this));
  }
}

export default new App();
